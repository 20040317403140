<template>
  <div></div>
</template>
<script>
import cookies from '../cookies.js';

export default {
  beforeCreate(){
      cookies.removeCookie('t');
      this.$router.replace('/');
  }
}
</script>
